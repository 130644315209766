
.card .loader-container[data-v-82a8b720]{
    position: absolute;
}
#close-button[data-v-82a8b720] {
    position: absolute;
    right: 0;
    top: 0;
}
#resize-button[data-v-82a8b720] {
    position: absolute;
    top: 0;
    right: 45px;
}
.slide-enter-active[data-v-82a8b720] {
   -moz-transition-duration: 1s;
   -webkit-transition-duration: 1s;
   -o-transition-duration: 1s;
   transition-duration: 1s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}
.slide-leave-active[data-v-82a8b720] {
   -moz-transition-duration: 1s;
   -webkit-transition-duration: 1s;
   -o-transition-duration: 1s;
   transition-duration: 1s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-enter-to[data-v-82a8b720], .slide-leave[data-v-82a8b720] {
   max-height: 500px;
   overflow: hidden;
}
.slide-enter[data-v-82a8b720], .slide-leave-to[data-v-82a8b720] {
   overflow: hidden;
   max-height: 0;
}
